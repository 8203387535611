import IconCryptoTrx from "cryptocurrency-icons/svg/color/trx.svg";
import IconCryptoEth from "cryptocurrency-icons/svg/color/eth.svg";
import IconCryptoBNB from "cryptocurrency-icons/svg/color/bnb.svg";
import IconCryptoPLY from "cryptocurrency-icons/svg/color/matic.svg";
import IconCryptoSOL from "cryptocurrency-icons/svg/color/sol.svg";

export const networkImages = {
  "BSC":IconCryptoBNB,
  "BSC_TESTNET":IconCryptoBNB,
  "ETHEREUM":IconCryptoEth,
  "RINKEBY":IconCryptoEth,
  "POLYGON":IconCryptoPLY,
  "MUMBAI_TESTNET":IconCryptoPLY,
  "SOLANA":IconCryptoSOL
}

export const supportedIcons = {
    'FRM':'https://assets.coingecko.com/coins/images/8251/small/frm.png?1563777564',
    'ETH': IconCryptoEth,
    'RVF': 'https://assets.coingecko.com/coins/images/14728/small/7.png?1618414105',
    'PAID': 'https://assets.coingecko.com/coins/images/13761/small/PAID.png?1612493556'
}